<template>
  <div class="confirm">
    <mt-header class="header" :title="$t('loan')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
    </mt-header>
    <div class="content">
      <div class="tips">
        {{ $t('loanTips') }}
      </div>
      <div class="container">
        <div class="check-content">
          <div class="check-content-amt">$ {{totalTransferMoney | formatMoney}} </div>
          <div class="check-content-tip">{{$t('checkContentTip')}}</div>
        </div>
        <div class="check-box-main">
          <div class="check-header">
               <input type="checkbox" class="check-box" value="all" v-model="checkedAll" @change='changeState'>
               <span class="check-header-text">{{$t('repayTotalAmount')}}</span>
               <div class="check-header-amt">$ {{totalRepayMoney | formatMoney}} </div>
          </div>
          <div class="check-box-option">
            <ul class="check-ul">
              <li class="check-li" v-for = "item in productList" :key = "item.org">
                <div class="check-item1">
                  <input type="checkbox" class="check-box" :value="item.org"  v-model="checkedList" @change='selected(item)' >
                  <img class="check-icon" :src="item.imageLink" alt="">
                  <span class="pd-content">{{item.productName}}</span>
                  <span class="amt-content">$ {{item.loanAmount}}</span>
                </div>
                <div class="check-item">
                  <span class="check-item-title">{{$t('loanTerm')}}</span>
                  <span class="check-item-content">{{item.loanTerms}} días</span>
                </div>
                <div class="check-item">
                  <span class="check-item-title">{{$t('repaymentAmount')}}</span>
                  <span class="check-item-content" @click="showDetails(item)">
                    <span class="check-item-content" >$ {{item.repaymentAmount}}</span>
                    <i class="iconfont">&gt;</i>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="content-bank">
          <mt-cell :title="$t('bankCardNo')">
            <div class="mt-cell-text">{{ user.customerDto.bankCardNo }}</div>
          </mt-cell>
          <mt-cell :title="$t('clickModifyBankCard')" is-link to="/step/modifyBankInfo">
          </mt-cell>
        </div>
      </div>
    </div>
    <!-- <mt-cell class="loanAgreementBox">
      <label class="mint-checklist-label" slot="title">
        <span class="mint-checkbox">
          <input class="mint-checkbox-input" type="checkbox" v-model="currentValue" :value="1">
          <span class="mint-checkbox-core"></span>
        </span>
        <div class="loanAgreement">
          {{ $t('clickingAgreeOur') }}
          <span @click.stop.prevent="onLoanAgreement">
            <b>{{ $t('loanAgreement') }}</b>
          </span>
        </div>
      </label>
    </mt-cell> -->
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit" :disabled="!currentValue">{{
        $t("submit")
      }}</mt-button>
    </div>

    <template>
      <div class="modal" v-if="madeVisible">
        <mt-cell :title="$t('loanAmount')">
          <div class="mt-cell-text">${{ productItem.loanAmount | formatMoney }}</div>
        </mt-cell>
        <mt-cell :title="$t('loanTerm')">
          <div class="mt-cell-text">{{ productItem.displayLoanDays || productItem.loanTerms }} {{ $t('day') }}</div>
        </mt-cell>
        <mt-cell :title="$t('transferAmount')">
          <div class="mt-cell-text">${{ productItem.scheduleCalcResultDto.actualAmount | formatMoney }}</div>
        </mt-cell>
        <mt-cell title="Tarifa">
          <div class="mt-cell-text">${{ ( productItem.repaymentAmount - productItem.scheduleCalcResultDto.actualAmount)| formatMoney }}</div>
        </mt-cell>
        <div class="nav"></div>
        <mt-cell :title="$t('repaymentAmount')">
          <div class="mt-cell-text">${{ productItem.repaymentAmount | formatMoney }}</div>
        </mt-cell>
        <mt-button class="made-button" type="primary" size="large"  @click="madeVisible = false">Close</mt-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {scheduleCalc, myCoupon, useMyCoupon, getUserInfo, productList, sign} from '../../utils/api'
import branch from '../../../node_modules/branch-sdk'
import gtmPageView from '../../utils/gtm.js'
import { geolocation } from '../../utils/index'
import Vue from 'vue'
import {formatMoney} from "../../filters/list";
export default {
  name: 'Confirm',
  components: {
  },
  data() {
    return {
      currentValue: true,
      amount: 0,
      oldAmount: 0,
      period: {},
      product: {},
      productList:[],
      checkedAll:true,
      checkedList: [], // 存放已选中项的数据
      totalTransferMoney : 0,
      totalRepayMoney : 0,
      trial: {},
      bank:{},
      // 红包
      coupon: [],
      popupVisibleCoupon: false,
      madeVisible: false,
      productItem:{},
      couponItem: {},
      trialTimeout: null
    }
  },
  computed: {
    ...mapState(["user", "brand"]),
    step() {
      return 100 / ((this.product.maxViewAmount - this.product.minAmount) / this.product.amountStep)
    },
    amountValue() {
      return (Math.round(this.amount / 100 * (this.product.maxViewAmount - this.product.minAmount) / this.product.amountStep) * this.product.amountStep) + this.product.minAmount
    },
    trialTerms() {
      return this.period.loanTerms
    },
    couponList() {
      return this.coupon.map(e => ({
        ...e,
        label: `${e.name} ${this.$options.filters.formatMoney(e.amount)}`
      }))
    }
  },
  watch: {
    amountValue() {
      clearTimeout(this.trialTimeout)
      this.trialTimeout = setTimeout(() => {
        this.getTrial()
      }, 1000)
    },
    trialTerms() {
      this.getTrial()
    },
    checkedList:{
      handler(){
        this.checkedAll = this.checkedList.length === this.productList.length;
      },
      deep:true
    }
  },
  created() {
    gtmPageView(this)
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P08_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P08_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    formatMoney,
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      await Promise.all([
        this.getUser(),
        this.getProduct(),
        // this.getMyCoupon(),
      ])
      this.$indicator.close();
      this.$NProgress.done();
    },
    changeState(){
      this.checkedList = []
      this.totalRepayMoney = 0
      this.totalTransferMoney = 0
      if(this.checkedAll === true){
        this.productList.forEach( item => this.checkedList.push(item.org))
        this.productList.forEach(item => this.totalRepayMoney += item.repaymentAmount)
        this.productList.forEach(item => this.totalTransferMoney += item.scheduleCalcResultDto.actualAmount)
      } else {
        this.totalRepayMoney = 0
        this.totalTransferMoney = 0
      }
    },
    selected(item){
      let a = this.checkedList.some( arr => {
        return arr === item.org
      })
      if(a){
        this.totalRepayMoney += item.repaymentAmount
        this.totalTransferMoney += item.scheduleCalcResultDto.actualAmount
      }else{
        this.totalRepayMoney -= item.repaymentAmount
        this.totalTransferMoney -= item.scheduleCalcResultDto.actualAmount
      }
    },
    showDetails(item){
      this.madeVisible = true
      this.productItem = item;
    },
    onLoanAgreement() {
      if (this.brand.channelInfo) {
        window.open(this.brand.channelInfo.serviceUrl)
      }
    },
    onRange(val) {
      if (this.oldAmount != val) {
        this.BEHAVIOR_ADD({
          id: 'P08_C01_S_LOANAMT',
          newValue: val
        })
        this.oldAmount = val
      }
      this.amount = val
    },
    async getUser() {
      await this.$axios({
        method: "POST",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_USER(e.body);
          this.bank.bankCardNo = e.body.customerDto.bankCardNo
        }
      }).catch(() => {})
    },
    // 打开弹窗
    onPopupPicker(options, callback) {
      this.$refs.popupPicker.openPicker({
        label: 'label',
        options,
        callback
      })
    },
    addStar(val, front, after){
      if (!val) return ''
      return val.substring(0,front)+'*'.repeat(val.length-(front+after))+val.substring(val.length-after)
    },
    // 获取未使用的优惠卷
    async getMyCoupon() {
      await this.$axios({
        method: "get",
        url: myCoupon,
        params: {
          status: '0'
        }
      })
        .then(async (e) => {
          if (e.status.code == "000" && e.body.length) {
            this.coupon = e.body
            this.$nextTick(() => {
              this.$set(this, 'couponItem', this.couponList[0])
            })
            this.popupVisibleCoupon = true
          }
        })
        .catch(() => {});
    },
    // 获取产品
    async getProduct() {
      await this.$axios({
        method: "post",
        url: productList,
        data: {
          source: "H5",
          phone: this.user.phone,
        }
      })
        .then((e) => {
          if (e.status.code === "000") {
            this.productList = e.body.productList
            this.changeState()
          } else {
            this.$toast(e.status.msg)
          }
        })
        .catch(() => {});
    },
    // 获取试算
    async getTrial() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      await this.$axios({
        method: "post",
        url: scheduleCalc,
        data: {
          loanAmt: Math.min(this.amountValue, this.product.maxAmount),
          loanDay: this.period.loanTerms,
          loanCode: this.period.loanCode,
          productCode: this.period.productCode,
        }
      })
        .then((e) => {
          if (e.status.code == "000") {
            this.trial = e.body || {}
          } else {
            this.$toast(e.status.msg)
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done();
    },
    // 使用优惠卷
    async onUseMyCoupon() {
      await this.$axios({
        method: "post",
        url: useMyCoupon + '?couponId=' + this.couponItem.id,
        params: {
          confirm: 'Y',
        }
      })
        .then(() => {})
        .catch(() => {});
    },
    // 签约
    async submit() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      try {
        // 根据是否已结清来判断是否是续贷或首贷
        if (this.user.applyStatus !== 'SETTLE') {
          let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
          if (pixel) {
            Vue.analytics.fbq.event('07_CONFIRM', { content_name: '07_CONFIRM' })
          }
          // Google Analytics  FIRST_CONFIRM
          if (localStorage['ga']) {
            window.gtag('event', 'FIRST_CONFIRM')
          }
        } else {
          let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
          if (pixel) {
            Vue.analytics.fbq.event('12_CONTINUED_CONFIRM', { content_name: '12_CONTINUED_CONFIRM' })
          }
          // Google Analytics  CONTINUED_CONFIRM
          if (localStorage['ga']) {
            window.gtag('event', 'CONTINUED_CONFIRM')
          }
        }
      } catch(e) {
        console.log(e)
      }
      let products = [];
      this.productList.forEach( item => {
        if(this.checkedList.includes(item.org)){
          const obj = {}
          obj.displayLoanDays = item.displayLoanDays
          obj.loanAmount = item.loanAmount
          obj.loanCode = item.loanCode
          obj.loanTerms = item.loanTerms
          obj.org = item.org
          products.push(obj)
        }
      })
      let data = {
        deviceId: "H5",
        source: "H5",
        channel: "H5",
        gps: await geolocation(),
        autoRepayment: true,
        applyId: this.user.applyId,
        currentStep: 8,
        totalSteps: 8,
        authFlag: 'Y',
        products:products
      }
      await this.$axios({
        method: "post",
        url: sign,
        params: { applyId: this.user.applyId },
        data: data,
      })
        .then(async (e) => {
          if (e.status.code === "000") {
            await this.$router.push('/apply/done')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done();
      branch.logEvent('12_CONTINUED_CONFIRM', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.confirm {
  background: url("../../assets/confirm-bg.png") no-repeat;
  background-size: 100% auto;
  .header{
    background: #a984f6;
    color: #FFFFFF;
  }
  .mt-cell-text{
    color: #190529;
    font-weight: Bold;
  }
  .made-button{
    margin-top: 20px;
  }
  .nav{
    margin: 10px 10px 10px;
    border-bottom: 1px solid #FFFFFF;
  }
  /deep/ .mint-cell{
    background-color: transparent;
    min-height: 40px;
  }
  /deep/ .mint-cell-wrapper{
    background: transparent;
  }
  .content-bank{
    background: #FFFFFF;
    margin: 10px 20px 10px 20px;
    border-radius: 20px;
  }
  .container {
    background: linear-gradient(to bottom, #BB97FA, #FFFFFF);
    margin: 10px 20px 10px 20px;
    border-radius: 20px;
    font-size: 16px;
    .check-content{
      padding-top: 50px;
      height: 80px;
      text-align: center;
      color: #FFFFFF;
      .check-content-amt{
        font-size: 40px;
        font-weight: bold;
      }
      .check-content-tip{
        margin: 5px auto;
      }
    }
    .check-box-main{
      background: #FFFFFF;
      margin: 10px 20px 10px 20px;
      border-radius: 20px;
      min-height: 360px;
    }
    .check-header{
      display: flex;
      justify-content: center;
      padding: 20px 20px;
      height: 30px;
      .check-header-text{
        margin-left: 10px;
      }
      .check-header-amt{
        margin-left: auto;
      }
    }
    .check-box-option{
      display: flex;
      justify-content: center;
    }
    .check-box{
      vertical-align:middle;
      height: 20px;
      width: 20px;
    }

    input[type=checkbox] {
      cursor: pointer;
      position: relative;
      width: 20px;
      height: 20px;
      font-size: 16px;
    }

    input[type=checkbox]::after {
      position: absolute;
      top: 0;
      color: #000;
      width: 20px;
      height: 20px;
      display: inline-block;
      visibility: visible;
      text-align: center;
      content: ' ';
      border-radius: 3px
    }

    input[type=checkbox]:checked::after {
      content: "✓";
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      background-color: #855EF4;
    }
    /deep/ ul{
      padding-inline-start: 0;
    }
    .check-ul{
      margin: 10px 20px 10px 20px;
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      position: relative;
    }
    .check-li{
      list-style: none;
      margin: 10px auto;
      width: 100%;
      border-bottom: 1px solid #c2aff6;
    }
    .check-li:last-child{
      border: none;
    }
    .check-item1{
      display: flex;
      justify-content: left;
      height: 30px;
      .iconfont{
        margin: 0 5px;
        font-size: 16px;
        color: #000000;
      }
    }
    .check-item{
      display: flex;
      justify-content: left;
      margin: 10px auto;
      .iconfont{
        margin: 0 5px;
        font-size: 16px;
        color: #000000;
      }
    }
    .check-item-title{
      font-size: 12px;
    }
    .check-item-content{
      flex: 1;
      text-align:right;
    }
    .pd-content{
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      font-size: 20px;
    }
    .amt-content{
      height: 30px;
      line-height: 30px;
      flex: 1;
      text-align:right;
      color: #795da3;
      font-weight: Bold;
    }
    .check-icon {
      margin-left: 10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  .content {
    .tips {
      text-align: center;
      padding: 10px;
      margin-top: 20px;
      font-size: 12px;
      color: #FFFFFF;
    }
    .orderConfirm {
      margin:  10px 20px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      img {
        font-size: 0;
        width: 16px;
        vertical-align: sub;
      }
    }
    .call {
      padding: 10px 20px;
      .title {
        margin:  10px 20px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        img {
          font-size: 0;
          width: 16px;
          vertical-align: sub;
        }
      }
      >.content {
        padding: 10px 10px;
        margin: 10px 0px;
        border-radius: 6px;
        box-shadow: 0px 0px 10px 0px rgba(210,210,210,0.5);
        /deep/ .mint-cell-value {
          color: brown;
          font-weight: 600;
        }
        .box {
          display: inline-block;
          position: relative;
          border-radius: 13px;
          background: #E2F0DC;
          .lock {
            position: absolute;
            height: 25px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.5;
          }
          .button {
            position: relative;
            display: inline-block;
            min-width: 60px;
            height: 24px;
            padding: 0px 5px;
            font-size: 14px;
            line-height: 24px;
            border-radius: 13px;
            font-weight: bold;
            text-align: center;
            color: #47B526;
            &.primary {
              color: white;
              background-color: #4F9B41;
            }
            &.disabled {
              color: white;
              background-color: #BCDEAE;
            }
          }
        }
      }
      .info {
        padding: 10px 0px;
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        .tips {
          margin: 0px 10px;
          font-size: 14px;
          border: 1px solid #ccc;
          padding: 5px 10px;
          border-radius: 5px;
          background-color: #ffef94;
          flex: 1;
        }
        .content {
          flex: 1;
        }
      }
    }
    .trial {
      border: 1px solid #bbdefb;
      border-radius: 5px;
      margin: 0 20px;
      display: block;
      padding: 1px;
      tr {
        display: flex;
      }
      td {
        padding: 10px;
        background: #f0f9ff;
        border: 1px solid #bbdefb;
        width: 50%;
        margin: 1px;
        .name {
          font-size: 12px;
          color: #5a5a5a;
        }
      }
    }
  }
  /* 样式定义 */
  .modal {
    position: fixed; /* 将模态框固定在页面中间位置 */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 300px;
    border-radius: 20px;
    background-color: #F9F6FF;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
  }
  .loanAgreementBox {
    margin-top: 10px;
    /deep/ .mint-cell-wrapper {
      background-image: none;
    }
  }
  .bankCard {
    width: 24px;
  }
  .stages {
    .item {
     box-shadow: 0px 0px 10px 0px rgba(210,210,210,0.5);
      margin: 10px 20px;
      padding: 10px;
      border-radius: 5px;
      .title {
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 18px;
      }
      /deep/ .mint-cell-value {
        color: brown;
        font-weight: 600;
      }
      .free {
        padding: 5px 10px;
        background: #F15234;
        color: white;
        border-radius: 5px;
        margin-left: 5px;
      }
    }
  }
  .bottom {
    padding: 20px 20px 20px 20px;
    .mint-button {
      background-color: #4F9B41;
      border-radius: 6px;
      font-weight: 600;
    }
  }
  .iconfont {
    margin: 0 5px;
    color: black;
  }

}
</style>